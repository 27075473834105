var _ts = _ts || [];
(function() {
    var account_id = $('meta[name=ts_account_id]').attr('content');
    var property_id = $('meta[name=ts_property_id]').attr('content');
    var passthru = $('meta[name=ts_passthru]').attr('content');

    var t   = document.createElement('script');
    t.type  = 'text/javascript';
    t.async = true;
    t.id    = 'ts-track';
    t.setAttribute('tracking-id', account_id + '-' + property_id);
    t.setAttribute('passthru', passthru);
    t.src = ('https:' == document.location.protocol ? 'https://' : 'http://')+'www.trackingstatalytics.com/ts-track-v2_0.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(t, s);
})();

var _tsCheck = _tsCheck || [];
(function () {
    _tsCheck = {
        tries: 0,
        maxtries: 10,
        retryafter: 1000,
        ready: function (f) {
            var self = this;
            if (typeof _ts.track == 'function') {
                return f()
            } else {
                if (this.tries++<=this.maxtries) {
                    window.setTimeout(function () { self.ready(f) },this.retryafter)
                } else {
                    return false
                }
            }
        },
        exportPageviewReady: function (f) {
            var self = this;
            if (typeof _tsReady.exportPageview == 'function') {
                return f()
            } else {
                if (this.tries++<=this.maxtries) {
                    window.setTimeout(function () { self.ready(f) },this.retryafter)
                } else {
                    return false
                }
            }
        },
        elementReady: function (elementid,f) {
            var self = this;
            var element =  document.getElementById(elementid);
            if (typeof(element) != 'undefined' && element != null) {
                return f()
            } else {
                if (this.tries++<=this.maxtries) {
                    window.setTimeout(function () { self.elementReady(elementid,f) },this.retryafter)
                } else {
                    return false
                }
            }
        }
    }
})();
