$(document).ready(function(){

    //Navigation Menu Slider
    $('#nav-expander').on('click',function(e){
        e.preventDefault();
        $('body').toggleClass('nav-expanded');
        $('.lp-page-wrapper').toggleClass('blur');
    });
    $('#nav-close').on('click',function(e){
        e.preventDefault();
        $('body').removeClass('nav-expanded');
        $('.lp-page-wrapper').removeClass('blur');
    });

    // Initialize navgoco with default options
    $(".main-menu").navgoco({
        caret: '<span class="caret"></span>',
        accordion: false,
        openClass: 'open',
        save: true,
        cookie: {
            name: 'navgoco',
            expires: false,
            path: '/'
        },
        slide: {
            duration: 300,
            easing: 'swing'
        }
    });

    var nav = $('.filter-sort');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 375) {
            nav.addClass("fix-filter-sort");
        } else {
            nav.removeClass("fix-filter-sort");
        }
    });

    $('#perpage').on('change', function() {
        filterCars();
    });

    $('.filter-cars-btn').on('click', function () {
        //filterCars();
    });

    function filterCars(){
        $('input[name="pp"]').val($('select[name="pp"]').val());
        var $form = $( '#search-form' );
        var params = $form.serializeArray();

        $('.filter-cars').submit();
    }

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    $('.split-list-4:visible').columnize({
        columns: 4,
        buildOnce: false
    });

    $('.split-list-2:visible').columnize({
        columns: 2,
        buildOnce: false
    });

    $('.split-list-3:visible').columnize({
        columns: 3,
        buildOnce: false
    });

});