// Configuration
var OAS_url = '//oasc10015.247realmedia.com/RealMedia/ads/';
var OAS_sitepage = $('meta[name=oas_sitepage]').attr('content');
var OAS_listpos = $('meta[name=oas_listpos]').attr('content');
var OAS_query = $.param({
    screenwidth: screen.width <= 850 ? 850 : 851
}).concat( $('meta[name=oas_query]').attr('content') );
var OAS_target = '_top';
var OAS_version = 11;
var OAS_rn = new String(Math.random());
var OAS_rns = OAS_rn.substring (2, 11);

if (navigator.userAgent.indexOf('Mozilla/3') != -1 || navigator.userAgent.indexOf('Mozilla/4.0 WebTV') != -1) {
    OAS_version = 10;
} else if (OAS_version >= 11) {
    document.write('<script src=\"' + OAS_url + 'adstream_mjx.ads/' + OAS_sitepage + '/1' + OAS_rns + '@' + OAS_listpos + '?' + OAS_query + '\"><\/script>');
}

function OAS_NORMAL(pos)
{
    document.write('<A HREF=\"' + OAS_url + 'click_nx.ads/' + OAS_sitepage + '/1' + OAS_rns + '@' + OAS_listpos + '!' + pos + '?' + OAS_query + '\" TARGET=' + OAS_target + '>');
    document.write('<IMG SRC=\"' + OAS_url + 'adstream_nx.ads/' + OAS_sitepage + '/1' + OAS_rns + '@' + OAS_listpos + '!' + pos + '?' + OAS_query + '\" BORDER=0></A>');
}

function OAS_AD(pos)
{
    if (OAS_version >= 11) {
        OAS_RICH(pos);
    } else {
        OAS_NORMAL(pos);
    }
}
